<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card class="primary--border" outlined>
          <v-card-title> Deleted Users </v-card-title>
          <v-card outlined>
            <v-card-title class="title mb-0 pb-0">
              <v-flex xs4>
                <v-text-field
                  id="staff_name_filter"
                  hint="Atleast 3 characters"
                  text
                  outlined
                  dense
                  color="primary"
                  class="pa-0 staff_name_filter"
                  label="Search by Name, Email or Phone"
                  v-model="search"
                  append-icon="search"
                  @input="searchMember"
                />
              </v-flex>
               <transition
                name="animate-css-transition"
                enter-active-class="animated fadeInRight"
                leave-active-class="animated fadeOutRight"
              >
                <v-btn
                  id="deleted_user_clear_filter"
                  v-if=" search.length"
                  outlined
                  color="primary"
                  text
                  @click="getDeletedUsers(),search = ''"
                  class="mt-n7 staff_clear_filter"
                >
                  Clear
                </v-btn>
              </transition>
            </v-card-title>
          </v-card>
          <v-data-table
            :headers="headers"
            :items="deletedUsers"
            :options.sync="pagination"
            :footer-props="footerProps"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td align="left">{{ ++index }}</td>
                <td align="left" class="text-xs-left">
                  {{ item.name }}
                </td>
                <td align="left" class="text-xs-left">
                  {{ item.company.company_name }}
                </td>
                <td align="left" class="text-xs-left">
                  {{ item.email }}
                </td>
                <td align="left" class="text-xs-left">
                  {{ item.mobile }}
                </td>
                <td class="text-right">
                  <v-btn
                    color="warning"
                    outlined
                    x-small
                    @click="setData(item)"
                  >
                    Restore
                  </v-btn>
                  &nbsp;

                  <v-btn
                    color="error"
                    outlined
                    x-small
                    @click="
                      (userId = item.id),
                        (deleteDialog = true),
                        (companyId = item.company.company_id)
                    "
                  >
                    Delete
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="dialog" persistent max-width="350">
      <v-card>
        <v-card-title class="title pa-3 warning white--text"> </v-card-title>
        <v-card-text>
          <br />
          <alert-message
            extra-small
            type="warning"
            title="Please wait !!"
            message="Are you sure you want to restore this user"
          ></alert-message>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text small @click="resetData()">Cancel</v-btn>
          <v-btn color="warning" text small @click="restoreUser()">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialog" persistent max-width="450">
      <v-card>
        <v-card-title class="title pa-3 error white--text"
          >Delete User
        </v-card-title>
        <v-card-text>
          <br />
          <alert-message
            extra-small
            type="error"
            title="Please wait !!"
            message="Enter password to delete the user"
          ></alert-message>
        </v-card-text>
        <v-card-text>
          <div>
            <v-row>
              <v-col md="12" sm="12">
                <v-text-field
                  autocomplete="off"
                  label="Password *"
                  required
                  class="pa-0"
                  outlined
                  dense
                  type="password"
                  v-model="password"
                />
                <v-text-field
                  autocomplete="off"
                  label="Confirm Password *"
                  required
                  class="pa-0"
                  outlined
                  dense
                  type="password"
                  v-model="confirm_password"
                />
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text small @click="resetData()">Cancel</v-btn>
          <v-btn color="warning" text small @click="deleteUserPermanently()"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import Mixins from "@/library/Mixins";

export default {
  mixins: [Mixins],
  data: () => ({
    userId: "",
    companyId: "",
    deletedUsers: [],
    dialog: false,
    deleteDialog: false,
    password: "",
    confirm_password: "",
    headers: [
      { text: "#", align: "left", value: "sno", width: 5, sortable: false },
      { text: "Name", align: "left", value: "name", sortable: false },
      {
        text: "Company Name",
        align: "left",
        value: "company_name",
        sortable: false,
      },
      { text: "Email", align: "left", value: "email", sortable: false },
      { text: "Mobile", align: "left", value: "primary_mobile" },
      { text: "Action", align: "right", sortable: false },
    ],
    search: "",
    pagination: {},
  }),
  watch: {
    pagination: function () {
      this.getDeletedUsers();
    },
  },
  mounted() {
    this.getDeletedUsers();
  },
  methods: {
    setData(data) {
      this.dialog = true;
      this.userId = data.id;
      this.companyId = data.company.company_id;
    },
    resetData() {
      this.dialog = false;
      this.userId = "";
      this.companyId = "";
      this.deleteDialog = false;
      this.password = "";
      this.confirm_password = "";
    },
    searchMember: function (v) {
      if (v.length > 2 || !v.length) this.getDeletedUsers();
    },
    getDeletedUsers() {
      let extraParams = "search=" + this.search.toString();
      let query = this.queryString(extraParams);
      this.$rest
        .get("/api/deleted-users?=" + query)
        .then(({ data }) => {
          this.deletedUsers = data.data;
          this.pagination.totalItems = data.meta.total;
        })
        .catch((err) => {
          this.$events.fire("notification", {
            message: err.data.message,
            status: "error",
          });
        });
    },
    restoreUser() {
      this.$rest
        .put("/api/restore-user/" + this.userId)
        .then((res) => {
          if (res.status == 200) {
            this.$events.fire("notification", {
              message: res.data.message,
              status: "success",
            });
            this.resetData();
            this.getDeletedUsers();
          }
        })
        .catch((e) => {
          this.$events.fire("notification", {
            message: e.data.message,
            status: "error",
          });
        });
    },
    deleteUserPermanently() {
      if (this.password == "" || this.confirm_password == "") {
        return this.$events.fire("notification", {
          message: "Password or confirm password cannot be empty",
          status: "error",
        });
      }
      if (this.password !== this.confirm_password) {
        return this.$events.fire("notification", {
          message: "password and confirm password did not match ",
          status: "error",
        });
      }
      this.$rest
        .put("/api/user-delete-permanently/" + this.userId, {
            companyId:this.companyId,
          password: this.password,
          password_confirmation: this.confirm_password,
        })
        .then((res) => {
          if (res.status == 200) {
            this.$events.fire("notification", {
              message: res.data.message,
              status: "success",
            });
          }
          this.resetData();
          this.getDeletedUsers();
        })
        .catch((e) => {
          this.$events.fire("notification", {
            message: e.response.data.message,
            status: "error",
          });
        });
    },
  },
};
</script>
